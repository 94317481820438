.container {
  text-align: center;
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
}

.form-container {
  /*background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F7944D);*/
  /* ff 3.6+ */
  background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
  
  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

  padding: 1rem 5rem;
  /*border: 1px solid white;
  border-radius: 10px;*/
  margin: 0.5rem;
}

.form-container,  .register-form {
  display: flex;
  flex-direction: column;
}

/*@media screen and (min-width: 600px) {
  .form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
}*/

label {
  text-align: left;
  padding: 0.25rem 0;
}



.register-form input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

.register-form {
  display: flex;
}

.register-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.register_container input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}



.register-form2 input {
  display: flex;
  border: none;
  border-radius: 10px;
  width: 250px ;
  padding: 0.8rem;
}

.register-form2 spam {
  width: 250px ;
}

.depositInput{
  padding: 0.8rem;
  width: 340px;
  margin-top: 10px;
  margin-bottom: 30px;
}


.form_container button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}

.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}

.cqc__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  background-size:  contain;
  background-position: top;
  background-repeat: no-repeat;
  height: 45rem;
  left: 0;
  right: 0;
  overflow: hidden;
}

.title {
  font-size: 30px;
  display: flex;
  flex-direction: column; 
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  color: white; 
}


.cqc__text1 spam {
  font-size: 3em;
  padding-bottom: 0rem;
  margin-bottom: 0;
}

spam {
  font-size: 1.5em;
  color: white;
}

.error_text {
  color:rgb(255, 44, 44);
  padding-top: 0.2rem;
}

.cardField {
  width: 25rem;
  height: 10rem;
}

.reg-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.reg-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.backgroundImage {
  position: absolute;
  height: 42rem;
  width: 100%;
  object-fit: cover;
  position:absolute;
  top:0; 
  left: 0;
}

.register_body {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 45rem;
  width: 100%;
  left: 0;
  top:0; 
}

.register_container {
  text-align: center;
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  color: white;
  position:relative;
}