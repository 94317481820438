.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
  align-items: center;
}

.bottom_text {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.gallery_button{
  margin: 0 5px;
  height: 25px;
  width: 25px;
  padding: 8px 16px;
  background-color: #FFFFFF;
  color:black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.galleryImage {
  height: auto;
  width: auto;
  max-height: 700px;
  margin-bottom: 20px;
  margin-top: 20px;
}