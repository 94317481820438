
 body {
   box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: white;
    display: flex;
    
    flex-direction: column;


    
    overflow: auto;
  
 }
 
 a {
    color: unset;
    text-decoration: none;
  }

 .App{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
  } 
 .App-second {
   min-height:100vh;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   background-color: #fff;
 }
 
 nav {
  transition: 0.3s ease-in-out;
}
nav.sticky {
  box-shadow: 0px 5px 10px rgba(0,0,0,0.6);
  position: sticky;
  top: 0;
  z-index: 100;
}

.Clasic {
  display: flex;
  flex-direction: column;
}

.Page {
  padding: 0rem 0 0rem;
  position: relative;
  background: white;
}
.fulNav {
  background-color: var(--color-footer);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fulNav p{
  padding: 0.4rem;
  margin: 0;
}



.cqc__fulNavP{
  color: #FF4820;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.cqc__fulNavP2 {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

::-ms-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

::-moz-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

::scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.Animation{

  /* ff 3.6+ */
  background:-moz-linear-gradient(180deg, rgba(2, 22, 73, 1) 13%, rgba(5, 66, 133, 1) 48%, rgba(2, 22, 73, 1) 87%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(180deg, rgba(2, 22, 73, 1) 13%, rgba(5, 66, 133, 1) 48%, rgba(2, 22, 73, 1) 87%);

  /* opera 11.10+ */
  background:-o-linear-gradient(180deg, rgba(2, 22, 73, 1) 13%, rgba(5, 66, 133, 1) 48%, rgba(2, 22, 73, 1) 87%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#021649', endColorstr='#021649', GradientType=1 );

  /* ie 10+ */
  background:-ms-linear-gradient(180deg, rgba(2, 22, 73, 1) 13%, rgba(5, 66, 133, 1) 48%, rgba(2, 22, 73, 1) 87%);

  /* global 94%+ browsers support */
  background:linear-gradient(180deg, rgba(2, 22, 73, 1) 13%, rgba(5, 66, 133, 1) 48%, rgba(2, 22, 73, 1) 87%);
   width:100%;
   height: 100vh;

   overflow: hidden;

   
 }

.round{
   border-radius: 3rem;
}

.round_2{
   border-top-left-radius: 3rem;
   border-bottom-right-radius: 3rem;
}

.FormGroup {
	margin: 0 15px 20px;
	padding: 0;
	border-style: none;
	background-color: #7795f8;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
	border-radius: 4px;
  margin-top: 15px;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}



.PayButton {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px);
	height: 45px;
	border-radius: 4px;
  margin:15px;
  background-color: #7795f8;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}

.PayButton:active {
	background-color: #d782d9;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
	transform: scale(0.99);
}


.blue_button {
  padding: 0.5rem 1rem;
  margin: 15px 15px 15px 15px; 
  color: white;
  background: #163278;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
}

 