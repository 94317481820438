.tutorials {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.tutorial_container {
    text-align: center;
    display: flex;
    padding: 0rem;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
  }

.tutorials_body {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    width: 100%;
    left: 0;
    top: 0;
}

.tutorials_video_row {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4rem;
    margin-bottom: 0rem;
}

.tutorial_processing_image_sets{
    width: 100%;
    align-self: center;
    align-items: center;
}

.tutorial_processing_image_sets ol{
    align-self: center;
    font-size: 20px;
}

.tutorials h1 {
    font-size: 40px;
    margin-top: 0;
}

.clip {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0rem;
}
.clip iframe {
    width: 100%;
    height: 100%;
}

.newVideo {
    width: 100%;
    height: 100%;
}

.clip_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem 1rem;
}

.image {
    display: flex;
    flex-direction: row;
}

.image img {
    width: 100%;
    height: 100%;
}

.att_video {
    width: 100%;
    height: 100%;
}

.att_video_column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}