.containerP{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.Profile_container {
    display:flex;
    flex-direction: column;
    width: 52rem;
    border: solid black 2px;
    border-radius: 1rem;
}

.Frst{
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 2rem;
    padding-bottom: 0.5rem;
}

.profile_tokens_row_coin{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    height: 20px;
}

.token_animation{
    margin-top: 10px;
    height: 60px;
    align-self: center;
}

.ImageP {
    flex: 1;
}

.ImageP img {  
    width:  100%  ;
    height: 100% ;
    aspect-ratio: 7/8;
    border-radius: 1rem;
}

.Name {
    flex: 5;
}

.Name p {
    margin-left: 3rem;
    margin-bottom: 0;
    font-size: 34px;
}
.SecondP {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.TextProfile {
    display: flex;
    width: auto;
    flex-direction: column;
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.cardField {
    width: 25rem;
    height: 10rem;
}

.cardField input {
    margin-left: 0px;
}

.divRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.divRow2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.divColumn {
    display: flex;
    flex-direction: Column;
    align-items: flex-start;
    border: solid black 2px;
    border-radius: 1rem;
}

.small {
    font-size: 20px;
    margin-bottom: 0rem;
}

.bold {
    margin-left: 0.5rem;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0rem;        
}

.btnAdd{
    display: flex;
    align-items: flex-end;
    margin: 1rem;
}

.depositInput2{
    padding: 0.8rem;
    width: 340px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
}

.reg-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .reg-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .profile-form {
    display: flex;
    flex-direction: column;
  }
  

  .container {
    text-align: center;
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
  }

  .login_body {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    left: 0;
    top: 0;
  }

  .backgroundImage {
    position: absolute;
    height: 42rem;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  

  h1 {
	text-align: center;
	margin-bottom: 20px;
    margin-top: 0px;
}

.profile {
	display: flex;
	flex-direction: column;
	align-items: center;
    color: black;
	padding: 20px;
    margin-bottom: 50px;
    height: 41rem;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
    background:  rgb(255, 255, 255, 0.6);
}

.profile-link-btn {
    background: none;
    border: none;
    color: black;
    font-size: 16px;
    text-decoration: underline;
}

.profile-link-btn:hover {
color: #7ec7f8;
}

.profile__name {
	font-size: 2.5rem;
	font-weight: bold;
	margin-bottom: 20px;
}

.profile table {
    table-layout: fixed;
    align-content: center;
    width: 500px;
    margin-left: 30px;
    margin-right: 30px;
}

.profile td, th {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    text-align: right;
}


.profile td:first-child {
    font-weight: bold;
    text-align: left;
}
