.Challenge {
    padding: 2rem 10rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    background-color: white;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(back.jpeg);
}
.BlackBox{
    margin:0rem -20rem;
    text-align: center;
    color: white;
    background: black;
}
.BlackBox button {
    font-size: 16px;
    font-weight: 720;
    color: black;
}
.BlackBox h2 {
    font-size: 27px;
    padding:0rem 20.5rem;
}

.BlackBox button {
    margin-bottom: 1.8rem ;
}

.Challenge h1 {
    font-size: 40px;
    margin-top: 0;
}

.TextBox{
    align-self: center;
    color: black;
}

.TextBlock {
    width: 600px;
    text-align: left;
    font-size: large;
}
.TextBlock {
    font-size: large;
}
.TextBlock h2,
.H2 {
    margin-bottom: 1rem;
}
.H2 {
    font-size: 27px;
}

.TextBlock li {
    font-size: 22px;
}

@media screen and  (max-width : 1600px) {
    .Challenge {
        padding: 2rem  10rem ;
    }
}

@media screen and  (max-width : 1450px) {
    .Challenge {
        padding: 2rem  8rem ;
    }
}

@media screen and  (max-width : 1100px) {
    .Challenge {
        padding: 2rem  5rem ;
    }
}

@media screen and  (max-width : 950px) {
    .Challenge {
        padding: 2rem  4rem ;
    }
    .container-slider {
        height: 25rem;
    }
    .btn-slide{
        width: 50px;
        height: 50px;
    }
    .dot {
        width: 17px;
        height: 17px;
    }
}


@media screen and (max-width:800px) {
    .Challenge {
        padding: 2rem 6rem 2rem;
    }
    .TextBlock p {
        font-size: 20px;
    }
    .TextBlock li {
        font-size: 20px;
    }
    .container-slider {
        height: 20rem;
    }
    .btn-slide{
        width: 40px;
        height: 40px;
    }
    .dot {
        width: 14px;
        height: 14px;
    }
}

@media screen and (max-width:700px) {
    .Challenge {
        padding: 2rem 4rem 2rem;
    }
    .Challenge h1{
        font-size: 35px;
    }
}

@media screen and (max-width:600px) {
    .Challenge {
        padding: 2rem 3rem 2rem;
    }
    .TextBlock h2,
    .H2{
        font-size: 24px;
    }
    .BlackBox h2 {
        font-size: 24px;
    }
    .TextBlock p {
        font-size: 18px;
    }
    .TextBlock li {
        font-size: 18px;
    }
}

@media screen and (max-width:500px) {
    .Challenge {
        padding: 2rem 2rem 2rem;
    }
    .Challenge h1 {
        font-size: 32px;
    }
    .TextBlock {
        padding: 1rem 0rem;
    }
    .TextBlock h2,
    .H2{
        font-size: 22px;
        margin-top: 1rem;
    }
    .BlackBox h2 {
        font-size:22px;
    }
    .TextBlock p {
        font-size: 16px;
    }
    .TextBlock li {
        font-size: 16px;
    }
    .container-slider {
        height: 15rem;
    }
    .btn-slide{
        width: 30px;
        height: 30px;
    }
    .btn-slide img {
        width: 15px;
        height: 15px;
    }
    .dot {
        width: 11px;
        height: 11px;
    }
}

@media screen and (max-width:400px) {
    .Challenge h1 {
        font-size: 28px;
    }
}

.challange_submissions {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    align-self: center;
    gap: 1rem
}

.challange_submission_image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    height: 500px;
    width: 50%;
}

.challange_submission_image img {
    height: 100%;
    width: 100%;
}

.challange_submission_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 21px;
    width: 1000px;
    margin-bottom: 5rem;
    margin-right: 2rem;
}

.challange_submission_text button {
    width: 200px;
}

.challange_submission_button {
    height: auto;
    width: 1rem;
    color: black;
}