.container {
  text-align: center;
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
}

.form-container {
  background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F7944D);
  padding: 5rem;
  border-radius: 10px;
  margin: 0.5rem;
}

.form-container {
  display: flex;
  flex-direction: column;
}



label {
  text-align: left;
  padding: 0.25rem 0;
}


.form_container button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}

.link-btn {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  text-decoration: underline;
}

.link-btn:hover {
  color: #7ec7f8;
}

.backgroundImage {
  position: absolute;
  height: 42rem;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.login_body {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 45rem;
  margin-top: 5rem;
  width: 100%;
  left: 0;
  top: 0;
}

.title {
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 1rem;
  color: white;
}

.reg-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.reg-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  width: 250px;
  border-radius: 10px;
}